/**
 * SECTION. OUR SERVICES
 */
(function () {
  window.addEventListener('DOMContentLoaded', resizeThrottler, false);
  window.addEventListener('resize', resizeThrottler, false);

  let resizeTimeout;
  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function () {
        resizeTimeout = null;
        actualResizeHandler();

        // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
    }
  }

  function actualResizeHandler() {
    if (window.innerWidth >= 1200) {
      const controller = new ScrollMagic.Controller();

      const serviceContainer = document.querySelector('.section-service');
      // let titleHeight = document.querySelector('.section-service .col-left .col-inner').clientHeight;
      let fixingHieght = document.querySelector('.section-service .col-right')
        .clientHeight;

      // let last_known_scroll_position = 0;
      let ticking = false;

      // Fix service title on scroll
      // eslint-disable-next-line no-inner-declarations
      function stickyServiceTitleOnScroll() {
        let _scrollTop = window.scrollY || document.documentElement.scrollTop;

        document.querySelector(
          '.section-service .col-left',
        ).style.height = fixingHieght;

        if (_scrollTop >= serviceContainer.offsetTop) {
          document
            .querySelector('.section-service .col-left .col-inner')
            .classList.remove('top-status');
        } else {
          document
            .querySelector('.section-service .col-left .col-inner')
            .classList.add('top-status');
        }
      }

      window.addEventListener('scroll', () => {
        // last_known_scroll_position = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            stickyServiceTitleOnScroll();
            // stickySectionOnScroll(last_known_scroll_position);
            ticking = false;
          });

          ticking = true;
        }
      });

      new ScrollMagic.Scene({
        triggerElement: serviceContainer,
        triggerHook: 0,
        duration: fixingHieght - 300,
        reverse: true,
      })
        .setClassToggle(serviceContainer, 'custom-sticky')
        .addTo(controller);
    }
  }
})();

/**
 * SECTION. CONTACT US
 */
(function ($, window, document, undefined) {
  /**
   * Responsive form group (반응형 입력폼 & 레이블)
   */
  function getTargetParent(el, targetClass) {
    let currEl = el;
    while (currEl && !currEl.classList.contains(targetClass)) {
      currEl = currEl.parentNode;
    }
    return currEl;
  }
  function addFocusListener(el, targetClass) {
    let targetParent = getTargetParent(el, targetClass);
    if (targetParent) {
      el.addEventListener('focus', function () {
        targetParent.classList.add('focus');
      });
      el.addEventListener('blur', function () {
        targetParent.classList.remove('focus');
      });
    }
  }

  const inputs = document.querySelectorAll(
    '.responsive-form-group .form-control',
  );
  for (let i = 0; i < inputs.length; i++) {
    addFocusListener(inputs[i], 'responsive-form-group');
  }

  /**
   * Button click event
   */
  if (document.body.animate) {
    document
      .getElementById('openModalContectUs')
      .addEventListener('click', pop);
  }

  function pop(e) {
    // Quick check if user clicked the button using a keyboard
    if (e.clientX === 0 && e.clientY === 0) {
      const bbox = document
        .getElementById('openModalContectUs')
        .getBoundingClientRect();
      const x = bbox.left + bbox.width / 20;
      const y = bbox.top + bbox.height / 20;
      for (let i = 0; i < 40; i++) {
        // We call the function createParticle 30 times
        // We pass the coordinates of the button for x & y values
        createParticle(x, y);
      }
    } else {
      let target = e.target;
      for (let i = 0; i < 40; i++) {
        // We call the function createParticle 30 times
        // As we need the coordinates of the mouse, we pass them as arguments
        createParticle(
          target.offsetLeft + target.offsetWidth / 2,
          target.offsetTop + target.offsetHeight / 2,
        );
        // createParticle(e.clientX, e.clientY);
      }
    }
  }
  function createParticle(x, y) {
    const particle = document.createElement('particle');
    document.getElementById('sectionContactUs').appendChild(particle);

    // Calculate a random size from 5px to 25px
    const size = Math.floor(Math.random() * 30 + 10);
    particle.style.width = `${10}px`;
    particle.style.height = `${10}px`;
    // Generate a random color in a blue/purple palette
    particle.style.background =
      'hsl(' + (Math.random() * 90 + 100) + ', 87%, 60%)'; // `hsl(${Math.random() * 90 + 100}, 87%, 60%)`;

    // Generate a random x & y destination within a distance of 75px from the mouse
    const destinationX = x + (Math.random() - 0.5) * 10 * 250;
    const destinationY = y + (Math.random() - 0.5) * 3 * 250;

    // Store the animation in a variable as we will need it later
    const animation = particle.animate(
      [
        {
          // Set the origin position of the particle
          // We offset the particle with half its size to center it around the mouse
          transform: 'translate(-50%, -50%) translate(' + x + 'px,' + y + 'px)', // `translate(-50%, -50%) translate(${x}px, ${y}px)`,
          opacity: 1,
        },
        {
          // We define the final coordinates as the second keyframe
          transform:
            'translate(' + destinationX + 'px, ' + destinationY + 'px)', // `translate(${destinationX}px, ${destinationY}px)`,
          opacity: 0,
        },
      ],
      {
        // Set a random duration from 500 to 1500ms
        duration: Math.random() * 2000 + 500,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        // Delay every particle with a random value of 200ms
        delay: Math.random() * 200,
      },
    );

    // When the animation is complete, remove the element from the DOM
    animation.onfinish = () => {
      particle.remove();
    };
  }

  /**
   * Open a contact modal after a delay
   */
  const contectUsModalTrigger = document.getElementById('openModalContectUs');

  contectUsModalTrigger.addEventListener('click', () => {
    setTimeout(() => {
      $('#modalContectUs').modal();
    }, 500);
  });

  /**
   * Open another modal in bootstrap modal
   */
  // $(document).on('show.bs.modal', '.modal', function () {
  //   var zIndex = 1040 + 10 * $('.modal:visible').length;
  //   $(this).css('z-index', zIndex);
  //   setTimeout(function () {
  //     $('.modal-backdrop')
  //       .not('.modal-stack')
  //       .css('z-index', zIndex - 1)
  //       .addClass('modal-stack');
  //   }, 0);
  // });
  // $(document).on('hidden.bs.modal', '.modal', function () {
  //   var zIndex = 1030 + 10 * $('.modal:visible').length;
  //   $(this).css('z-index', zIndex);

  //   // If there are any visible
  //   if ($('.modal:visible').length > 0) {
  //     // Slap the class on it (wait a moment for things to settle)
  //     setTimeout(function () {
  //       $('body').addClass('modal-open');
  //       $('.modal-backdrop.modal-stack')
  //         .css('z-index', zIndex - 1)
  //         .removeClass('modal-stack');
  //     }, 200);
  //   }
  // });
})(jQuery, window, document);
